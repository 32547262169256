var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('a-form-model',{staticStyle:{"margin-bottom":"2vh"},attrs:{"layout":"inline"},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.query.apply(null, arguments)}}},[_c('a-row',[_c('a-col',{attrs:{"span":22}},[_c('a-form-model-item',[_c('a-input',{staticStyle:{"width":"150px"},attrs:{"placeholder":"采购单号"},model:{value:(_vm.form.purchaseOrder),callback:function ($$v) {_vm.$set(_vm.form, "purchaseOrder", $$v)},expression:"form.purchaseOrder"}})],1),_c('a-form-model-item',[_c('a-select',{staticStyle:{"width":"150px"},attrs:{"placeholder":"审核状态"},model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}},_vm._l((_vm.statusList),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.name))])}),1)],1),_c('a-form-model-item',[_c('a-range-picker',{staticStyle:{"width":"300px"},attrs:{"placeholder":['开始采购时间', '结束采购时间'],"format":"YYYY-MM-DD"},model:{value:(_vm.form.time),callback:function ($$v) {_vm.$set(_vm.form, "time", $$v)},expression:"form.time"}})],1),_c('a-form-model-item',[_c('a-space',[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.query}},[_vm._v("查询")]),_c('a-button',{on:{"click":_vm.reset}},[_vm._v("重置")])],1)],1)],1),_c('a-col',{attrs:{"span":2}},[_c('div',{staticClass:"right"},[_c('a-button',{attrs:{"type":"primary","disabled":!_vm.permissionAdd},on:{"click":function($event){return _vm.$router.push(`${_vm.$route.path}/add`)}}},[_vm._v("创建采购单")])],1)])],1)],1),_c('a-table',{attrs:{"bordered":"","data-source":_vm.list,"loading":_vm.loading,"rowKey":"id","pagination":{
      total: _vm.total,
      current: _vm.current,
      pageSize: _vm.pageSize,
      showTotal: (total) => `共 ${total} 条记录`,
    }},on:{"change":_vm.onChange}},[_c('a-table-column',{attrs:{"title":"采购单号","data-index":"purchaseOrder","align":"center"}}),_c('a-table-column',{attrs:{"title":"采购时间","data-index":"purchaseTime","align":"center"}}),_c('a-table-column',{attrs:{"title":"采购员","data-index":"purchaser","align":"center"}}),_c('a-table-column',{attrs:{"title":"审核状态","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [_c('DataDictFinder',{attrs:{"dictType":"AssetReviewStatus","dictValue":text.status,"iconType":"text"}})]}}])}),_c('a-table-column',{attrs:{"title":"审核人员","data-index":"reviewer","align":"center"}}),_c('a-table-column',{attrs:{"title":"审核时间","data-index":"reviewTime","align":"center"}}),_c('a-table-column',{attrs:{"title":"操作","width":"240px","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [_c('a-space',{directives:[{name:"show",rawName:"v-show",value:(_vm.permissionEdit),expression:"permissionEdit"}]},[(text.status === 'REVIEWING' || text.status === 'REJECTED')?_c('a',{on:{"click":function($event){return _vm.$router.push(`${_vm.$route.path}/edit?id=${text.id}`)}}},[_vm._v("重新申请")]):_vm._e(),_c('a',{on:{"click":function($event){return _vm.$router.push(`${_vm.$route.path}/detail?id=${text.id}`)}}},[(text.status === 'REVIEWING')?_c('span',[_vm._v("审核")]):(text.status === 'APPROVED')?_c('span',[_vm._v("接收")]):_c('span',[_vm._v("详情")])]),(text.status !== 'FINISHED' && text.status !== 'CANCELLED')?_c('a',{staticClass:"danger",on:{"click":function($event){$event.preventDefault();return _vm.cancelText(text)}}},[_vm._v("取消申请")]):_vm._e(),(text.status !== 'FINISHED' && text.status !== 'DELETED')?_c('a',{staticClass:"danger",on:{"click":function($event){$event.preventDefault();return _vm.deleteText(text)}}},[_vm._v("删除")]):_vm._e()])]}}])})],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-header"},[_c('img',{staticClass:"bg",attrs:{"src":require("@/assets/page-header-bg.png"),"alt":""}}),_c('span',{staticClass:"text"},[_vm._v("资产采购")])])
}]

export { render, staticRenderFns }