<template>
  <div>
    <div class="page-header">
      <img class="bg" src="@/assets/page-header-bg.png" alt="" />
      <span class="text">资产采购</span>
    </div>

    <a-form-model
      layout="inline"
      @keyup.enter.native="query"
      style="margin-bottom: 2vh"
    >
      <a-row>
        <a-col :span="22">
          <a-form-model-item>
            <a-input
              placeholder="采购单号"
              v-model="form.purchaseOrder"
              style="width: 150px"
            />
          </a-form-model-item>

          <a-form-model-item>
            <a-select
              v-model="form.status"
              placeholder="审核状态"
              style="width: 150px"
            >
              <a-select-option
                v-for="item in statusList"
                :key="item.value"
                :value="item.value"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-model-item>

          <a-form-model-item>
            <a-range-picker
              style="width: 300px"
              :placeholder="['开始采购时间', '结束采购时间']"
              format="YYYY-MM-DD"
              v-model="form.time"
            />
          </a-form-model-item>

          <a-form-model-item>
            <a-space>
              <a-button type="primary" @click="query">查询</a-button>
              <a-button @click="reset">重置</a-button>
            </a-space>
          </a-form-model-item>
        </a-col>

        <a-col :span="2">
          <div class="right">
            <a-button
              type="primary"
              @click="$router.push(`${$route.path}/add`)"
              :disabled="!permissionAdd"
              >创建采购单</a-button
            >
          </div>
        </a-col>
      </a-row>
    </a-form-model>

    <a-table
      bordered
      @change="onChange"
      :data-source="list"
      :loading="loading"
      rowKey="id"
      :pagination="{
        total,
        current,
        pageSize,
        showTotal: (total) => `共 ${total} 条记录`,
      }"
    >
      <a-table-column
        title="采购单号"
        data-index="purchaseOrder"
        align="center"
      />

      <a-table-column title="采购时间" data-index="purchaseTime" align="center">
      </a-table-column>
      <a-table-column title="采购员" data-index="purchaser" align="center">
      </a-table-column>

      <a-table-column title="审核状态" align="center">
        <template slot-scope="text">
          <DataDictFinder
            dictType="AssetReviewStatus"
            :dictValue="text.status"
            iconType="text"
          />
        </template>
      </a-table-column>
      <a-table-column title="审核人员" data-index="reviewer" align="center">
      </a-table-column>
      <a-table-column title="审核时间" data-index="reviewTime" align="center">
      </a-table-column>

      <a-table-column title="操作" width="240px" align="center">
        <template slot-scope="text">
          <a-space v-show="permissionEdit">
            <a
              v-if="text.status === 'REVIEWING' || text.status === 'REJECTED'"
              @click="$router.push(`${$route.path}/edit?id=${text.id}`)"
              >重新申请</a
            >
            <a @click="$router.push(`${$route.path}/detail?id=${text.id}`)">
              <span v-if="text.status === 'REVIEWING'">审核</span>
              <span v-else-if="text.status === 'APPROVED'">接收</span>
              <span v-else>详情</span>
            </a>
            <a
              class="danger"
              v-if="text.status !== 'FINISHED' && text.status !== 'CANCELLED'"
              @click.prevent="cancelText(text)"
              >取消申请</a
            >
            <a
              class="danger"
              v-if="text.status !== 'FINISHED' && text.status !== 'DELETED'"
              @click.prevent="deleteText(text)"
              >删除</a
            >
          </a-space>
        </template>
      </a-table-column>
    </a-table>
  </div>
</template>
<script>
import { fetchList, cancel, remove } from "../api/buy";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      form: {},
      list: [],
      loading: false,
      total: 0,
      current: 1,
      pageSize: 10,
    };
  },
  mounted() {
    this.getList();
  },
  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    statusList() {
      return this.findDataDict("AssetReviewStatus");
    },

    //增加权限显示控制
    ...mapGetters("auth", ["hasPermission"]),
    permissionAdd() {
      return this.hasPermission("model-analysis:asset:purchase:add");
    },
    permissionEdit() {
      return this.hasPermission("model-analysis:asset:purchase:update");
    },
  },
  methods: {
    getList() {
      this.loading = true;
      let { current, pageSize, form } = this;

      let purchaseTimeStart, purchaseTimeEnd;
      if (Array.isArray(form.time) && form.time.length === 2) {
        purchaseTimeStart = form.time[0].format("YYYY-MM-DD");
        purchaseTimeEnd = form.time[1].format("YYYY-MM-DD");
      }
      fetchList({
        pageNum: current,
        pageSize,
        ...form,
        time: undefined,
        purchaseTimeStart,
        purchaseTimeEnd,
      })
        .then((res) => {
          this.loading = false;
          if (Array.isArray(res.list)) {
            this.list = res.list;
            this.total = res.totalSize;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    onChange(pagination) {
      this.current = pagination.current;
      this.pageSize = pagination.pageSize;
      this.getList();
    },
    reset() {
      this.form = {};
      this.current = 1;
      this.getList();
    },
    query() {
      this.current = 1;
      this.getList();
    },

    cancelText(text) {
      const that = this;
      this.$confirm({
        title: "确认要取消吗？",
        onOk() {
          cancel({
            id: text.id,
          }).then(() => {
            that.getList();
          });
        },
      });
    },
    deleteText(text) {
      const that = this;
      this.$confirm({
        title: "确认要删除吗？",
        onOk() {
          remove({
            id: text.id,
          }).then(() => {
            that.getList();
          });
        },
      });
    },
  },
};
</script>



<style lang="less" scoped>
.page-header {
  position: relative;
  margin-bottom: 1vh;

  .bg {
    width: 240px;
    display: block;
  }

  .text {
    position: absolute;
    top: 10%;
    left: 0;
    bottom: 0;
  }
}
</style>